/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';

import type {
  GetBranchesByCountryCodeResponse,
  GetFilterableBranchesResponse,
  ImportBranchAssignmentsBody,
  ProblemDetails
} from '../../models';

import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];



/**
 * Returns branches by country code
 */
export const getBranchesByCountryCode = (
    countryCode: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetBranchesByCountryCodeResponse>(
      {url: `/branches/country/${countryCode}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetBranchesByCountryCodeQueryKey = (countryCode: string,) => {
    return [`/branches/country/${countryCode}`] as const;
    }

    
export const getGetBranchesByCountryCodeQueryOptions = <TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBranchesByCountryCodeQueryKey(countryCode);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBranchesByCountryCode>>> = ({ signal }) => getBranchesByCountryCode(countryCode, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(countryCode), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetBranchesByCountryCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getBranchesByCountryCode>>>
export type GetBranchesByCountryCodeQueryError = ErrorType<ProblemDetails>


export function useGetBranchesByCountryCode<TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBranchesByCountryCode>>,
          TError,
          Awaited<ReturnType<typeof getBranchesByCountryCode>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetBranchesByCountryCode<TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBranchesByCountryCode>>,
          TError,
          Awaited<ReturnType<typeof getBranchesByCountryCode>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetBranchesByCountryCode<TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetBranchesByCountryCode<TData = Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBranchesByCountryCode>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetBranchesByCountryCodeQueryOptions(countryCode,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of branches assigned to requests of the current user
 */
export const getFilterableBranches = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetFilterableBranchesResponse>(
      {url: `/branches`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFilterableBranchesQueryKey = () => {
    return [`/branches`] as const;
    }

    
export const getGetFilterableBranchesQueryOptions = <TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFilterableBranchesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFilterableBranches>>> = ({ signal }) => getFilterableBranches(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFilterableBranchesQueryResult = NonNullable<Awaited<ReturnType<typeof getFilterableBranches>>>
export type GetFilterableBranchesQueryError = ErrorType<ProblemDetails>


export function useGetFilterableBranches<TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFilterableBranches>>,
          TError,
          Awaited<ReturnType<typeof getFilterableBranches>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFilterableBranches<TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFilterableBranches>>,
          TError,
          Awaited<ReturnType<typeof getFilterableBranches>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFilterableBranches<TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetFilterableBranches<TData = Awaited<ReturnType<typeof getFilterableBranches>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFilterableBranches>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFilterableBranchesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Import Branch Country/ZipCode assignment in CSV format
 */
export const importBranchAssignments = (
    importBranchAssignmentsBody: ImportBranchAssignmentsBody,
 options?: SecondParameter<typeof customMutator>,) => {
      
      const formData = new FormData();
formData.append('File', importBranchAssignmentsBody.File)

      return customMutator<void>(
      {url: `/branches/import`, method: 'PUT',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getImportBranchAssignmentsMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importBranchAssignments>>, TError,{data: ImportBranchAssignmentsBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof importBranchAssignments>>, TError,{data: ImportBranchAssignmentsBody}, TContext> => {
    
const mutationKey = ['importBranchAssignments'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importBranchAssignments>>, {data: ImportBranchAssignmentsBody}> = (props) => {
          const {data} = props ?? {};

          return  importBranchAssignments(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportBranchAssignmentsMutationResult = NonNullable<Awaited<ReturnType<typeof importBranchAssignments>>>
    export type ImportBranchAssignmentsMutationBody = ImportBranchAssignmentsBody
    export type ImportBranchAssignmentsMutationError = ErrorType<ProblemDetails>

    export const useImportBranchAssignments = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importBranchAssignments>>, TError,{data: ImportBranchAssignmentsBody}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof importBranchAssignments>>,
        TError,
        {data: ImportBranchAssignmentsBody},
        TContext
      > => {

      const mutationOptions = getImportBranchAssignmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    