import { useGetCurrentContributor } from '@shared/api/queries/contributors/contributors';
import { ErrorLayout, LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-modal-promise';
import { Outlet } from 'react-router';

import { CurrentContributorProvider } from './CurrentContributorProvider';

export const Frame = () => {
    const { isLoading, data: currentContributor } = useGetCurrentContributor();
    const { t } = useTranslation();

    if (isLoading) {
        return <LoadingAnimation />;
    }

    if (!currentContributor) {
        return <ErrorLayout description={t('errorLoadingCurrentContributor')} />;
    }

    return (
        <CurrentContributorProvider currentContributor={currentContributor.data}>
            <Container />
            <Outlet />
        </CurrentContributorProvider>
    );
};
