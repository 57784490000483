import { useOidcUser } from '@axa-fr/react-oidc';
import { ActionIcon, Loader } from '@mantine/core';
import { Badge, Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { OnboardingRequestDetailModel } from 'api/models';
import { useGetIndustrialSectors } from 'api/queries/industrial-sectors/industrial-sectors';
import { Information, InformationSection } from 'components/Information/Information';
import { getBadgeVariant } from 'utils/getBadgeVariant';
type Props = {
    request: OnboardingRequestDetailModel;
    onEditClick?: () => void;
    mode: 'Frontend' | 'Backoffice';
};

type InformationItemProps = {
    icon?: string;
    title: string;
    children: React.ReactNode;
    className?: string;
};

const InformationItem = ({ icon, title, children, className = '' }: InformationItemProps) => {
    if (!children) {
        return null;
    }

    return (
        <div className={`flex ${icon ? '' : 'ml-5'} ${className}`}>
            {icon && <Icon className="text-icon-default mr-1" icon={icon} />}
            <InformationSection className="flex-grow" title={title}>
                <div className="text-text-light">{children}</div>
            </InformationSection>
        </div>
    );
};

export const CompanyInformation = ({ request, mode, onEditClick }: Props) => {
    const { t } = useTranslation();
    const { oidcUser } = useOidcUser();
    const { data: industrialSectorsResponse, isPending: areIndustrialSectorsLoading } = useGetIndustrialSectors({
        request: { headers: { 'Accept-Language': oidcUser.locale } }
    });

    if (!request) {
        return null;
    }

    const company = request.companyData;
    const industrialSector = industrialSectorsResponse?.data?.find(
        (sector) => sector.identifier === company.industrialSectorIdentifier
    );

    return (
        <Information className="p-md">
            <div className="flex justify-between">
                <div className="gap-base flex">
                    <InformationSection title={company.companyName ?? ''}>
                        <p className="text-text-light text-sm">{company.additionalCompanyName}</p>
                    </InformationSection>
                    {request.currentState && mode === 'Backoffice' && (
                        <Badge variant={getBadgeVariant(request.currentState)}>
                            {t(`RequestState.${request.currentState}`)}
                        </Badge>
                    )}
                </div>

                <div className="ml-auto">
                    {onEditClick && (
                        <ActionIcon onClick={onEditClick}>
                            <Icon className="text-text-default" icon="edit" />
                        </ActionIcon>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {company.mainAddress && (
                    <InformationItem icon="location_on" title={t('address')}>
                        <p>{company.mainAddress?.street}</p>
                        <p>
                            {company.mainAddress?.zipCode} {company.mainAddress?.city}
                        </p>
                        <p>{company.mainAddress?.countryConfiguration?.name}</p>
                    </InformationItem>
                )}

                <InformationItem icon="credit_card" title={t('uidNumber')}>
                    {company.vatNr}
                </InformationItem>

                {request?.administrators && request.administrators[0]?.model.email && (
                    <InformationItem icon="person" title={t('administrator')}>
                        <div className="flex flex-col gap-1">
                            <div className="text-text-light">{request.administrators[0]?.model.email}</div>
                            {request?.signers && request.signers[0]?.model.email && (
                                <div className="text-text-light">
                                    <div className="text-text-default text-base">{t('contractSignatory')}</div>
                                    {request.signers[0]?.model.email}
                                </div>
                            )}
                        </div>
                    </InformationItem>
                )}
                <InformationItem icon="call" title={t('generalOfficeEmail')}>
                    <div className="flex flex-col gap-1">
                        {company.email && <div className="text-text-light">{company.email}</div>}

                        {company.phone && (
                            <div className="text-text-light">
                                <div className="text-text-default text-base">{t('phoneNumber')}</div>
                                {company.phone}
                            </div>
                        )}

                        {company.mobilePhone && (
                            <div className="text-text-light">
                                <div className="text-text-default text-base">{t('mobilePhone')}</div>
                                {company.mobilePhone}
                            </div>
                        )}
                    </div>
                </InformationItem>
                <InformationItem title={t('taxNr')}>{company.taxNr}</InformationItem>
                <InformationItem title={t('icoNr')}>{company.icoNr}</InformationItem>
                <InformationItem title={t('industrialSectorIdentifier')}>
                    {areIndustrialSectorsLoading && <Loader />}
                    {!areIndustrialSectorsLoading && <>{industrialSector?.translation}</>}
                </InformationItem>

                {company.electronicInvoiceEmail && company.electronicInvoiceType && (
                    <InformationItem title={t('electronicInvoiceEmail')}>
                        {company.electronicInvoiceEmail}

                        <div className="text-text-light">
                            <div className="text-text-default text-base">{t('electronicInvoiceType')}</div>
                            {company.electronicInvoiceType}
                        </div>
                    </InformationItem>
                )}

                <InformationItem title={t('website')}>{company.website}</InformationItem>
                <InformationItem title={t('pecEmail')}>{company.pecEmail}</InformationItem>

                <InformationItem title={t('receiverCode')}>{company.receiverCode}</InformationItem>
            </div>
        </Information>
    );
};
