import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { i18n } from '../../../i18n';
import { EInvoiceType } from 'api/models';
import { CountryRule, FieldConfigurations } from './CountryRule';

export const companyBaseDataSchema = z
    .object({
        companyName: z
            .string()
            .min(1, i18n.t('isRequired', { label: i18n.t('companyName') }))
            .max(80, i18n.t('charsLimit', { label: i18n.t('companyName'), limit: 80 })),
        companyOwnerName: z.string().optional(),
        industrialSector: z.string().optional(),
        street: z.string(),
        zipCode: z.string().optional(),
        city: z.string().min(1, i18n.t('isRequired', { label: i18n.t('city') })),
        country: z.string().min(1, i18n.t('isRequired', { label: i18n.t('country') })),
        state: z.string().optional(),
        taxNumber: z.string().optional(),
        vatId: z.string().optional(),
        icoNumber: z.string().optional(),
        pecEmail: z.string().email(i18n.t('notValidMail')).or(z.literal('')).or(z.undefined()),
        sdiCode: z.string().optional(),
        useElectronicInvoice: z.boolean(),
        electronicInvoiceEmail: z.string().email(i18n.t('notValidMail')).or(z.literal('')).or(z.null()),
        electronicInvoiceType: z.nativeEnum(EInvoiceType).nullish(),
        generalOfficeMail: z
            .string()
            .min(1, i18n.t('isRequired', { label: i18n.t('generalOfficeMail') }))
            .email(i18n.t('notValidMail')),
        phoneNumber: z
            .string()
            .min(1, i18n.t('isRequired', { label: i18n.t('phoneNumber') }))
            .refine(
                (phoneNumber: string) => isValidPhoneNumber(phoneNumber),
                () => ({ message: i18n.t('notValidPhoneNumber') })
            ),
        mobileNumber: z
            .string()
            .optional()
            .refine(
                (phoneNumber: string | undefined) => !phoneNumber || isValidPhoneNumber(phoneNumber),
                () => ({ message: i18n.t('notValidPhoneNumber') })
            )
    })
    .superRefine((request, refinementContext) => {
        checkCountryRule(request, refinementContext);
        checkElectronicInvoiceEmail(
            request.useElectronicInvoice,
            request.electronicInvoiceEmail,
            request.electronicInvoiceType,
            refinementContext
        );
        return refinementContext;
    });

export const requestSchema = z
    .object({
        companyData: companyBaseDataSchema,
        technicalAdministrator: z
            .string()
            .min(1, i18n.t('isRequired', { label: i18n.t('technicalAdministrator') }))
            .email(i18n.t('notValidMail')),
        contractSignatory: z
            .string()
            .min(1, i18n.t('isRequired', { label: i18n.t('contractSignatory') }))
            .email(i18n.t('notValidMail')),
        businessLicence: z.string().optional(),
        existingCustomer: z.boolean()
    })
    .superRefine(
        (
            { businessLicence, companyData, existingCustomer, technicalAdministrator, contractSignatory },
            refinementContext
        ) => {
            const countryRule = CountryRule.FindById(companyData.country);

            if (
                !existingCustomer &&
                !businessLicence &&
                countryRule?.fieldConfigurations.businessLicenseUpload.mandatory
            ) {
                refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('isRequired', { label: i18n.t('businessLicence') })
                });
            }
            validateEmail(technicalAdministrator, 'technicalAdministrator', refinementContext);
            validateEmail(contractSignatory, 'contractSignatory', refinementContext);
        }
    );

export const invitationSchema = z.object({
    companyData: companyBaseDataSchema,
    companyRegistrar: z
        .string()
        .min(1, i18n.t('isRequired', { label: i18n.t('companyRegistrar') }))
        .email(i18n.t('notValidMail'))
});

export const companySchema = z.object({
    companyData: companyBaseDataSchema
});

export type CompanyBaseDataSchemaType = z.infer<typeof companyBaseDataSchema>;
export type CompanySchemaType = z.infer<typeof companySchema>;
export type RequestSchemaType = z.infer<typeof requestSchema>;

export type InvitationSchemaType = z.infer<typeof invitationSchema>;

const emailRegexString = import.meta.env.VITE_CONTACT_EMAIL_REGEX;
const emailRegex = new RegExp(emailRegexString);
const validateEmail = (email: string, field: string, refinementContext: z.RefinementCtx) => {
    if (!emailRegex.test(email)) {
        refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            path: [field],
            message: i18n.t('notValidMail')
        });
    }
};
function checkElectronicInvoiceEmail(
    useElectronicInvoice: boolean,
    electronicInvoiceEmail: string | null,
    electronicInvoiceType: EInvoiceType | null | undefined,
    refinementContext: z.RefinementCtx
) {
    if (useElectronicInvoice) {
        if (!electronicInvoiceEmail) {
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['electronicInvoiceEmail'],
                message: i18n.t('isRequired', { label: i18n.t('electronicInvoiceEmail') })
            });
        }
        if (!electronicInvoiceType) {
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['electronicInvoiceType'],
                message: i18n.t('isRequired', { label: i18n.t('electronicInvoiceType') })
            });
        }
    }
}

function checkCountryRule(request: CompanyBaseDataSchemaType, refinementContext: z.RefinementCtx) {
    const countryRule = CountryRule.FindById(request.country);

    if (countryRule) {
        for (const [key, value] of Object.entries(request)) {
            const fieldConfig = countryRule.fieldConfigurations[key as keyof FieldConfigurations];

            if (
                !fieldConfig ||
                (!request.useElectronicInvoice && fieldConfig.dependsOnElectricInvoice) ||
                !fieldConfig.visible
            ) {
                continue;
            }

            if (fieldConfig && fieldConfig.mandatory && !value) {
                refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('isRequired', {
                        label: i18n.t(key as keyof FieldConfigurations)
                    }),
                    path: [key]
                });
            }

            if (fieldConfig && !fieldConfig.mandatory && !value) {
                // don't validate empty fields if they are not mandatory
                continue;
            }

            if (typeof value === 'string' && fieldConfig.regex && !fieldConfig.regex.test(value ?? '')) {
                refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('invalidFormat', {
                        label: i18n.t(key as keyof FieldConfigurations),
                        sample: fieldConfig.regexExample
                    }),
                    path: [key]
                });
            }
        }
    }
}
