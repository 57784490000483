import { LoadingAnimation, toastNotifications, getExceptionDetails } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { welcomeApiClient } from 'api/customMutator';
import { OnboardingRequestModel } from 'api/models';
import { getDownloadAttachmentContentQueryKey, useGetAttachments } from 'api/queries/attachments/attachments';
import { getDownloadContractFileContentQueryKey, useGetSignedContractFiles } from 'api/queries/contracts/contracts';
import { downloadFile } from 'utils';
import { RequestAttachment } from './RequestAttachment';

type Props = {
    request: OnboardingRequestModel;
};

export const RequestAttachments = ({ request }: Props) => {
    const { data: attachmentsResponse, isPending: areAttachmentsPending } = useGetAttachments(request.id ?? '');
    const { data: contractsResponse, isPending: areContractsPending } = useGetSignedContractFiles(request.id ?? '');
    const attachments = attachmentsResponse?.data ?? [];
    const contracts = contractsResponse?.data ?? [];
    const { t } = useTranslation();

    const handleContractDownload = async (attachmentId: string, attachmentName: string) => {
        try {
            await downloadFile(
                welcomeApiClient,
                getDownloadContractFileContentQueryKey(request.id, attachmentId).join(),
                attachmentName
            );
        } catch (err) {
            toastNotifications.error({
                title: t('errorDownloadingContract'),
                message: getExceptionDetails(err)?.message ?? ''
            });
        }
    };

    const handleAttachmentDownload = async (attachmentId: string, attachmentName: string) => {
        try {
            await downloadFile(
                welcomeApiClient,
                getDownloadAttachmentContentQueryKey(request.id, attachmentId).join(),
                attachmentName
            );
        } catch (err) {
            toastNotifications.error({
                title: t('errorDownloadingAttachment'),
                message: getExceptionDetails(err)?.message
            });
        }
    };

    return (
        <>
            {attachments.some((attachment) => attachment.exists) && <div className="text-base">{t('documents')}</div>}
            <div className="gap-base grid grid-cols-1">
                {/* will be added later */}
                {/* {request.currentState == 'DataVerification' && (
                    <div className="py-xl flex w-full justify-center border px-3">
                        <FileButton
                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                            // onChange={() => console.log('test')}
                        >
                            {(props) => (
                                <Button className="image-picker-button px-xl py-2" {...props}>
                                    {t('uploadDocument')}
                                </Button>
                            )}
                        </FileButton>
                    </div>
                )} */}
                {(areAttachmentsPending || areContractsPending) && <LoadingAnimation />}
                {!areAttachmentsPending && !areContractsPending && (
                    <>
                        {attachments &&
                            attachments
                                .filter((attachment) => attachment.exists)
                                .map((attachment) => (
                                    <RequestAttachment
                                        key={attachment.id}
                                        attachmentType={attachment.attachmentType}
                                        fileName={attachment.fileName}
                                        fileSizeBytes={attachment.fileSizeBytes}
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        id={attachment.id!}
                                        onClick={() =>
                                            handleAttachmentDownload(
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                attachment.id!,
                                                attachment.fileName ?? 'unknown_file'
                                            )
                                        }
                                    />
                                ))}
                        {contracts &&
                            contracts
                                .filter((contract) => contract.exists)
                                .map((contract) => (
                                    <RequestAttachment
                                        key={contract.id}
                                        attachmentType={contract.attachmentType}
                                        fileName={contract.fileName}
                                        fileSizeBytes={contract.fileSizeBytes}
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        id={contract.id!}
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        onClick={() => handleContractDownload(contract.id!, contract.fileName!)}
                                    />
                                ))}
                    </>
                )}
            </div>
        </>
    );
};
