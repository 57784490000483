import RandExp from 'randexp';

import {
    GermanyRule,
    ItalyRule,
    USARule,
    CanadaRule,
    OmanRule,
    QatarRule,
    UaeRule,
    AustraliaRule,
    AustriaRule,
    BelgiumRule,
    BulgariaRule,
    ChileRule,
    CroatiaRule,
    CzechRepublicRule,
    DenmarkRule,
    EstoniaRule,
    FinlandRule,
    FranceRule,
    GreeceRule,
    HungaryRule,
    LatviaRule,
    LithuaniaRule,
    LuxembourgRule,
    NetherlandsRule,
    NorwayRule,
    PeruRule,
    PolandRule,
    PortugalRule,
    RomaniaRule,
    SerbiaRule,
    SlovakiaRule,
    SloveniaRule,
    SpainRule,
    SwedenRule,
    SwitzerlandRule,
    TurkeyRule,
    UkraineRule,
    UKRule
} from '../../../../../../Common/FieldSettings/countryRules';
import { EInvoiceType } from 'api/models';

const countryRules = [
    GermanyRule,
    ItalyRule,
    USARule,
    CanadaRule,
    OmanRule,
    QatarRule,
    UaeRule,
    AustraliaRule,
    AustriaRule,
    BelgiumRule,
    BulgariaRule,
    ChileRule,
    CroatiaRule,
    CzechRepublicRule,
    DenmarkRule,
    EstoniaRule,
    FinlandRule,
    FranceRule,
    GreeceRule,
    HungaryRule,
    LatviaRule,
    LithuaniaRule,
    LuxembourgRule,
    NetherlandsRule,
    NorwayRule,
    PeruRule,
    PolandRule,
    PortugalRule,
    RomaniaRule,
    SerbiaRule,
    SlovakiaRule,
    SloveniaRule,
    SpainRule,
    SwedenRule,
    SwitzerlandRule,
    TurkeyRule,
    UkraineRule,
    UKRule
];

export type FieldConfiguration = {
    visible: boolean;
    mandatory: boolean;
    dependsOnElectricInvoice?: boolean;
    regexString: string | null;
    regex?: RegExp;
    regexExample?: string;
    placeholder?: string;
};

export type FieldConfigurations = {
    companyOwnerName: FieldConfiguration;
    industrialSector: FieldConfiguration;
    street: FieldConfiguration;
    zipCode: FieldConfiguration;
    useElectronicInvoice: FieldConfiguration;
    state: FieldConfiguration;
    taxNumber: FieldConfiguration;
    vatId: FieldConfiguration;
    icoNumber: FieldConfiguration;
    pecEmail: FieldConfiguration;
    sdiCode: FieldConfiguration;
    mobileNumber: FieldConfiguration;
    businessLicenseUpload: FieldConfiguration;
    phoneNumber: FieldConfiguration;
};

export type CountryRuleConstructorProps = {
    id: string;
    fieldConfigurations: FieldConfigurations;
    eInvoiceTypes?: EInvoiceType[];
};

export class CountryRule {
    id: string;
    fieldConfigurations: FieldConfigurations;
    eInvoiceTypes?: EInvoiceType[];

    constructor({ id, fieldConfigurations, eInvoiceTypes }: CountryRuleConstructorProps) {
        this.id = id;
        this.eInvoiceTypes = eInvoiceTypes;
        this.fieldConfigurations = fieldConfigurations;

        Object.keys(this.fieldConfigurations).forEach((key) => {
            const fieldConfiguration = this.fieldConfigurations[key as keyof FieldConfigurations];
            if (fieldConfiguration.regexString) {
                fieldConfiguration.regex = new RegExp(fieldConfiguration.regexString);
                fieldConfiguration.regexExample = new RandExp(fieldConfiguration.regex).gen();
            }
        });
    }
    static countryRuleList: CountryRule[] | undefined = undefined;

    static All(): CountryRule[] {
        if (!this.countryRuleList) {
            this.countryRuleList = [];

            for (const countryRuleJson of countryRules) {
                this.countryRuleList.push(new CountryRule({ ...countryRuleJson }));
            }
        }
        return this.countryRuleList;
    }

    static FindById(id: string): CountryRule | undefined {
        return this.All().find((countryRule) => countryRule.id.toLowerCase() === id.toLowerCase());
    }

    isFieldAvailable = (field: keyof FieldConfigurations) =>
        this.fieldConfigurations && field in this.fieldConfigurations && this.fieldConfigurations[field] !== undefined;

    public getPlaceholder = (field: keyof FieldConfigurations, fallback: string) =>
        (this.isFieldAvailable(field) &&
            (this.fieldConfigurations[field].placeholder ?? this.fieldConfigurations[field].regexExample)) ||
        fallback;

    public isFieldVisible = (field: keyof FieldConfigurations) =>
        this.isFieldAvailable(field) && this.fieldConfigurations[field].visible;

    public isFieldMandatory = (field: keyof FieldConfigurations) =>
        this.isFieldAvailable(field) && this.fieldConfigurations[field].mandatory;

    public getEInvoiceTypes = () => {
        if (this.eInvoiceTypes && this.eInvoiceTypes.length > 0) {
            return this.eInvoiceTypes;
        }
        return Object.values(EInvoiceType);
    };
}
