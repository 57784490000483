import { TextInput, Button, Notification } from '@mantine/core';
import { useField } from '@mantine/form';
import { useCreateInvitationForExistingCustomer } from '@shared/api/queries/invitations/invitations';
import { getExceptionDetails, toastNotifications, variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { managementInstance } from '../../../i18n';

const validEmail = z
    .string()
    .min(1, managementInstance.t('isRequired', { label: managementInstance.t('companyRegistrar') }))
    .email(managementInstance.t('notValidMail'));

const validateEmail = (value: string) => {
    const result = validEmail.safeParse(value);

    if (result.success) {
        return undefined;
    }
    return JSON.parse(result.error.message)[0].message;
};

type Props = {
    customerNumber: string;
    onBack: () => void;
    onClose: () => void;
};

export const InviteExistingCompany = ({ customerNumber, onBack: handleBack, onClose }: Props) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: inviteExistingCustomer } = useCreateInvitationForExistingCustomer();

    const companyRegistrarField = useField<string>({
        initialValue: '',
        validate: (value) => validateEmail(value)
    });

    const handleSendInvitation = () => {
        companyRegistrarField.validate().then(async (result) => {
            if (!result) {
                setIsLoading(true);
                try {
                    await inviteExistingCustomer({
                        data: {
                            customerIdentifier: customerNumber,
                            invitee: { model: { email: companyRegistrarField.getValue() } }
                        }
                    });
                    toastNotifications.success({
                        title: t('success'),
                        message: t('invited', { data: t('company') })
                    });
                    onClose();
                } catch (error) {
                    toastNotifications.error({
                        title: t('failed'),
                        message: `${t('notInvited', { data: t('company'), reason: getExceptionDetails(error)?.message })}. `
                    });
                } finally {
                    setIsLoading(false);
                }
            }
        });
    };

    return (
        <>
            <Notification classNames={{ body: 'flex-wrap' }} title={t('dontKnowAllData')} withCloseButton={false}>
                {t('canAddDataLater')}
            </Notification>
            <TextInput
                description={t('companyRegistrarDescription')}
                label={t('companyRegistrar')}
                {...companyRegistrarField.getInputProps()}
                required
            />

            <div className="modal-footer mt-2">
                <Button loading={isLoading} variant={variants.button.secondary} onClick={handleBack}>
                    {t('back')}
                </Button>
                <Button loading={isLoading} onClick={handleSendInvitation}>
                    {t('sendInvitation')}
                </Button>
            </div>
        </>
    );
};
