import { Button } from '@mantine/core';
import { useResendInvitation } from '@shared/api/queries/invitations/invitations';
import { getGetRequestDetailQueryKey, getGetRequestsQueryKey } from '@shared/api/queries/requests/requests';
import { useQueryClient } from '@tanstack/react-query';
import { getExceptionDetails, Icon, toastNotifications, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { useRequestContext } from '../RequestProvider';
import { RequestActionButtonProps } from './types';

export const ResendInvitation = (_: RequestActionButtonProps) => {
    const { t } = useTranslation();
    const { mutateAsync: resendInvitation, isPending: isVerifyPending } = useResendInvitation();
    const queryClient = useQueryClient();
    const { request } = useRequestContext();

    const handleResendInvitation = async () => {
        try {
            await resendInvitation({ id: request.id });
            toastNotifications.success({
                title: t('success'),
                message: t('invitationResent')
            });
        } catch (error) {
            toastNotifications.error({
                title: t('failed'),
                message: t('resendInvitationFailed', {
                    reason: getExceptionDetails(error)?.message
                })
            });
        }
        queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(request.id) });
        queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
    };

    return (
        <Button loading={isVerifyPending} variant={variants.button.secondary} onClick={handleResendInvitation}>
            {t('resendInvitation')}
            <Icon className="ml-base" icon="notifications" />
        </Button>
    );
};
