/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';

import type {
  CreateOrUpdateRemarkModel,
  GetRemarksParams,
  GetRemarksResponse,
  ProblemDetails
} from '../../models';

import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];



/**
 * Returns a list of remarks
 */
export const getRemarks = (
    id: string,
    params?: GetRemarksParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetRemarksResponse>(
      {url: `/requests/${id}/remarks`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRemarksQueryKey = (id: string,
    params?: GetRemarksParams,) => {
    return [`/requests/${id}/remarks`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRemarksQueryOptions = <TData = Awaited<ReturnType<typeof getRemarks>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetRemarksParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRemarks>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRemarksQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRemarks>>> = ({ signal }) => getRemarks(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRemarks>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRemarksQueryResult = NonNullable<Awaited<ReturnType<typeof getRemarks>>>
export type GetRemarksQueryError = ErrorType<ProblemDetails>


export function useGetRemarks<TData = Awaited<ReturnType<typeof getRemarks>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetRemarksParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRemarks>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRemarks>>,
          TError,
          Awaited<ReturnType<typeof getRemarks>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRemarks<TData = Awaited<ReturnType<typeof getRemarks>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetRemarksParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRemarks>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRemarks>>,
          TError,
          Awaited<ReturnType<typeof getRemarks>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRemarks<TData = Awaited<ReturnType<typeof getRemarks>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetRemarksParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRemarks>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetRemarks<TData = Awaited<ReturnType<typeof getRemarks>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetRemarksParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRemarks>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetRemarksQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Adds a remark
 */
export const createRemark = (
    id: string,
    createOrUpdateRemarkModel: CreateOrUpdateRemarkModel,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/remarks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateRemarkModel, signal
    },
      options);
    }
  


export const getCreateRemarkMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRemark>>, TError,{id: string;data: CreateOrUpdateRemarkModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createRemark>>, TError,{id: string;data: CreateOrUpdateRemarkModel}, TContext> => {
    
const mutationKey = ['createRemark'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRemark>>, {id: string;data: CreateOrUpdateRemarkModel}> = (props) => {
          const {id,data} = props ?? {};

          return  createRemark(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateRemarkMutationResult = NonNullable<Awaited<ReturnType<typeof createRemark>>>
    export type CreateRemarkMutationBody = CreateOrUpdateRemarkModel
    export type CreateRemarkMutationError = ErrorType<ProblemDetails>

    export const useCreateRemark = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRemark>>, TError,{id: string;data: CreateOrUpdateRemarkModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createRemark>>,
        TError,
        {id: string;data: CreateOrUpdateRemarkModel},
        TContext
      > => {

      const mutationOptions = getCreateRemarkMutationOptions(options);

      return useMutation(mutationOptions);
    }
    