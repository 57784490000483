import { Avatar } from '@uag/react-core';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useRequestContext } from './RequestProvider';

export const RequestHeader = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation();
    const { request } = useRequestContext();
    const img = request.dokaOnboardingResponsible?.model.profilePictureUri;
    const name = request.companyOnboardingResponsible?.model.displayName;
    return (
        <div className="flex flex-row gap-base border-b border-border-default pb-base">
            <div className="flex flex-grow flex-col gap-1">
                <div className="flex gap-1">
                    <div>{request.companyData.companyName}</div>
                    {request.companyData.customerIdentifier && ` · ${request.companyData.customerIdentifier}`}
                </div>
                <div className="flex gap-2">
                    <Avatar size="xs" src={img} />
                    <div className="text-base text-text-light">
                        {t('requestHeaderDescription', {
                            requester: name,
                            date: new Date(request.createdDate).toDateString()
                        })}
                    </div>
                </div>
            </div>
            <div>{children}</div>
        </div>
    );
};
