import { TeamMemberModel } from '@shared/api/models';
import { ColumnDef } from '@tanstack/react-table';

export const getColumns = (): ColumnDef<TeamMemberModel>[] => [
    {
        accessorFn: (row: TeamMemberModel) => row,
        id: 'user',
        header: 'User',
        cell: ({ row }) => (
            <div className="flex items-center gap-2">
                <div className="h-8 w-8 overflow-hidden rounded-full">
                    {row.original.profilePictureUri ? (
                        <img alt="Avatar" className="h-full w-full object-cover" src={row.original.profilePictureUri} />
                    ) : (
                        <div className="avatar avatar-placeholder">
                            {`${row.original.firstName} ${row.original.lastName}`}
                        </div>
                    )}
                </div>
                <div>
                    <p className="font-default font-normal">
                        {row.original.firstName} {row.original.lastName}
                    </p>
                    <p className="text-sm text-text-light">{row.original.email}</p>
                </div>
            </div>
        )
    },
    {
        accessorFn: (row) => row.memberType,
        id: 'memberType',
        header: 'Permission',
        cell: ({ row }) => (
            <div className="data-table-cell-content">
                {row.original.memberType === 'Owner' ? 'Editor' : 'Team Member'}
            </div>
        )
    }
];
