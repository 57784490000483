/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';

import type {
  GetCountriesParams,
  GetCountriesResponse,
  GetCountryResponse,
  ProblemDetails
} from '../../models';

import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];



/**
 * Returns a country by its country code (ISO 3166-1 alpha-2 code)
 */
export const getCountryByCode = (
    countryCode: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetCountryResponse>(
      {url: `/countries/${countryCode}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCountryByCodeQueryKey = (countryCode: string,) => {
    return [`/countries/${countryCode}`] as const;
    }

    
export const getGetCountryByCodeQueryOptions = <TData = Awaited<ReturnType<typeof getCountryByCode>>, TError = ErrorType<ProblemDetails>>(countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountryByCode>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountryByCodeQueryKey(countryCode);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountryByCode>>> = ({ signal }) => getCountryByCode(countryCode, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(countryCode), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountryByCode>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetCountryByCodeQueryResult = NonNullable<Awaited<ReturnType<typeof getCountryByCode>>>
export type GetCountryByCodeQueryError = ErrorType<ProblemDetails>


export function useGetCountryByCode<TData = Awaited<ReturnType<typeof getCountryByCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountryByCode>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCountryByCode>>,
          TError,
          Awaited<ReturnType<typeof getCountryByCode>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCountryByCode<TData = Awaited<ReturnType<typeof getCountryByCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountryByCode>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCountryByCode>>,
          TError,
          Awaited<ReturnType<typeof getCountryByCode>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCountryByCode<TData = Awaited<ReturnType<typeof getCountryByCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountryByCode>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetCountryByCode<TData = Awaited<ReturnType<typeof getCountryByCode>>, TError = ErrorType<ProblemDetails>>(
 countryCode: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountryByCode>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetCountryByCodeQueryOptions(countryCode,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of countries
 */
export const getCountries = (
    params?: GetCountriesParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetCountriesResponse>(
      {url: `/countries`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCountriesQueryKey = (params?: GetCountriesParams,) => {
    return [`/countries`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCountriesQueryOptions = <TData = Awaited<ReturnType<typeof getCountries>>, TError = ErrorType<ProblemDetails>>(params?: GetCountriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountriesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountries>>> = ({ signal }) => getCountries(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCountries>>>
export type GetCountriesQueryError = ErrorType<ProblemDetails>


export function useGetCountries<TData = Awaited<ReturnType<typeof getCountries>>, TError = ErrorType<ProblemDetails>>(
 params: undefined |  GetCountriesParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCountries>>,
          TError,
          Awaited<ReturnType<typeof getCountries>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCountries<TData = Awaited<ReturnType<typeof getCountries>>, TError = ErrorType<ProblemDetails>>(
 params?: GetCountriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCountries>>,
          TError,
          Awaited<ReturnType<typeof getCountries>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCountries<TData = Awaited<ReturnType<typeof getCountries>>, TError = ErrorType<ProblemDetails>>(
 params?: GetCountriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useGetCountries<TData = Awaited<ReturnType<typeof getCountries>>, TError = ErrorType<ProblemDetails>>(
 params?: GetCountriesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetCountriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



