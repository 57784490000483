import { Button, Modal } from '@mantine/core';
import { useRemoveTeamMember } from '@shared/api/queries/teams/teams';
import { getExceptionDetails, toastNotifications, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { create, InstanceProps } from 'react-modal-promise';

type DeleteModalProps = {
    title: string;
    acceptButtonText: string;
    memberName: string;
    contributorId: string;
    teamId: string;
} & InstanceProps<void>;

const DeleteModal = ({
    title,
    acceptButtonText,
    memberName,
    contributorId,
    isOpen,
    onResolve,
    teamId,
    onReject
}: DeleteModalProps) => {
    const { mutateAsync: removeTeamMember, isPending } = useRemoveTeamMember();
    const { t } = useTranslation();

    const submit = async () => {
        try {
            await removeTeamMember({ id: teamId, contributorId });
            onResolve();
        } catch (err) {
            toastNotifications.error({
                title: t('unknownError'),
                message: t('unknownErrorDescription', {
                    reason: getExceptionDetails(err)?.message
                })
            });
        }
    };
    const handleReject = () => {
        onReject();
    };

    return (
        <Modal opened={isOpen} title={title} onClose={onReject}>
            <div className="flex w-[560px] flex-col gap-6">
                <div className="flex flex-col items-start gap-2">
                    <span className="text-base w-full font-normal leading-base text-text-ultra-light">
                        {t('removeMemberText', { name: memberName })}
                    </span>
                </div>
                <div className="flex flex-row justify-end gap-x-base">
                    <Button variant={variants.button.secondary} onClick={handleReject}>
                        {t('cancel')}
                    </Button>
                    <Button loading={isPending} variant={variants.button.danger} onClick={submit}>
                        {acceptButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export const openDeleteModal = create(DeleteModal);
