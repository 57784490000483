import { SelectableCard } from '@uag/react-core';
import { clsx } from 'clsx';

type Props = {
    text?: string;
    image?: string;
    value: string;
    className?: string;
    imageClassName?: string;
};
export const Card = ({ text, image, className, imageClassName, value }: Props) => {
    return (
        <SelectableCard
            className={clsx(className, 'flex w-full flex-col items-center justify-evenly gap-2 rounded-[5px] p-2')}
            value={value}
        >
            <div className="flex w-full flex-col items-center p-4">
                <div className="flex w-full justify-center">
                    <img alt="card" className={imageClassName} src={image} />
                </div>
                <div className="leading-sm font-default text-center text-sm font-normal">{text}</div>
            </div>
        </SelectableCard>
    );
};
