import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useGetCurrentContributor } from '@shared/api/queries/contributors/contributors';
import { useGetRequestDetail } from '@shared/api/queries/requests/requests';
import { CompanyForm } from '@shared/components/company/CompanyForm/CompanyForm';
import { CompanyInformation } from '@shared/components/company/ViewCompany/CompanyInformation';
import { RequestAttachments } from '@shared/components/company/ViewCompany/RequestAttachments';
import { LoadingAnimation } from '@uag/react-core';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ActivateCustomerAction } from './actions/ActivateCustomerAction';
import { AdditionalInputRequiredAction } from './actions/AdditionalDataInput';
import { ApproveRequestAction } from './actions/ApproveRequestAction';
import { DeclineRequestAction } from './actions/DeclineRequestAction';
import { ResendInvitation } from './actions/ResendInvitation';
import { Remarks } from './Remarks';
import { RequestActions } from './RequestActions';
import { RequestHeader } from './RequestHeader';
import { RequestProvider } from './RequestProvider';

export const Request = () => {
    const { requestId = '' } = useParams();
    const [editModalOpened, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
    const { data, isLoading } = useGetRequestDetail(requestId);
    const { data: currentContributorApiResponse } = useGetCurrentContributor();

    const { t } = useTranslation();
    const request = data?.data;
    const currentContributor = currentContributorApiResponse?.data;

    if (isLoading) {
        return <LoadingAnimation />;
    }
    const backgroundColor =
        request?.currentState === 'DataVerification' ? 'bg-background-white' : 'bg-background-gray-ultra-light';

    return (
        <div>
            {request && currentContributor && (
                <div className="flex flex-col gap-md">
                    <RequestProvider request={request}>
                        <RequestHeader>
                            <RequestActions>
                                <DeclineRequestAction visibleInStates={'DataVerification'} />
                                <ApproveRequestAction visibleInStates={'DataVerification'} />
                                <ActivateCustomerAction visibleInStates={'SignatureVerification'} />
                                <ResendInvitation visibleInStates={'Invited'} />
                            </RequestActions>
                        </RequestHeader>
                        <AdditionalInputRequiredAction />
                        <div className="flex flex-col gap-1">
                            <div className="text-base">{t('companyData')}</div>
                            <div className={clsx('text-sm border border-border-disabled', backgroundColor)}>
                                <CompanyInformation
                                    mode="Backoffice"
                                    request={request}
                                    onEditClick={
                                        request.currentState === 'DataVerification' ? openEditModal : undefined
                                    }
                                />

                                <Modal
                                    className="w-[960px]"
                                    opened={editModalOpened}
                                    title={t('editCompanyData')}
                                    onClose={closeEditModal}
                                >
                                    <CompanyForm mode="Backoffice" request={request} onClose={closeEditModal} />
                                </Modal>
                            </div>
                        </div>
                        <Remarks />
                        <RequestAttachments request={request} />
                    </RequestProvider>
                </div>
            )}
        </div>
    );
};
