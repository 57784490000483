/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';

import type {
  GetCompanyExistsResponse,
  ProblemDetails
} from '../../models';

import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];



/**
 * Checks if a company exists
 */
export const companyExists = (
    customerIdentifier: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetCompanyExistsResponse>(
      {url: `/companies/${customerIdentifier}/exists`, method: 'GET', signal
    },
      options);
    }
  

export const getCompanyExistsQueryKey = (customerIdentifier: string,) => {
    return [`/companies/${customerIdentifier}/exists`] as const;
    }

    
export const getCompanyExistsQueryOptions = <TData = Awaited<ReturnType<typeof companyExists>>, TError = ErrorType<ProblemDetails>>(customerIdentifier: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyExists>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCompanyExistsQueryKey(customerIdentifier);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof companyExists>>> = ({ signal }) => companyExists(customerIdentifier, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(customerIdentifier), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof companyExists>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type CompanyExistsQueryResult = NonNullable<Awaited<ReturnType<typeof companyExists>>>
export type CompanyExistsQueryError = ErrorType<ProblemDetails>


export function useCompanyExists<TData = Awaited<ReturnType<typeof companyExists>>, TError = ErrorType<ProblemDetails>>(
 customerIdentifier: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyExists>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof companyExists>>,
          TError,
          Awaited<ReturnType<typeof companyExists>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useCompanyExists<TData = Awaited<ReturnType<typeof companyExists>>, TError = ErrorType<ProblemDetails>>(
 customerIdentifier: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyExists>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof companyExists>>,
          TError,
          Awaited<ReturnType<typeof companyExists>>
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useCompanyExists<TData = Awaited<ReturnType<typeof companyExists>>, TError = ErrorType<ProblemDetails>>(
 customerIdentifier: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyExists>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }

export function useCompanyExists<TData = Awaited<ReturnType<typeof companyExists>>, TError = ErrorType<ProblemDetails>>(
 customerIdentifier: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof companyExists>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getCompanyExistsQueryOptions(customerIdentifier,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



