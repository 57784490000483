/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */

export type EInvoiceType = typeof EInvoiceType[keyof typeof EInvoiceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInvoiceType = {
  Pdf: 'Pdf',
  EInvoice: 'EInvoice',
} as const;
