import { ContributorDetailModel } from '@shared/api/models';
import { createContext, useContext } from 'react';

type CurrentContributorProviderProps = {
    children: React.ReactNode;
} & CurrentContributorContextProps;

type CurrentContributorContextProps = {
    currentContributor: ContributorDetailModel;
};

const CurrentContributorContext = createContext<CurrentContributorContextProps | undefined>(undefined);

export const CurrentContributorProvider = ({ children, currentContributor }: CurrentContributorProviderProps) => {
    return (
        <CurrentContributorContext.Provider value={{ currentContributor }}>
            {children}
        </CurrentContributorContext.Provider>
    );
};

export const useCurrentContributorContext = () => {
    const context = useContext(CurrentContributorContext);

    if (!context) {
        throw new Error('useCurrentContributorContext must be used within CurrentContributorProvider');
    }

    return context;
};
