import { Button, Select } from '@mantine/core';
import { TeamModel, TeamMemberModel } from '@shared/api/models';
import { useGetFilterableBranches } from '@shared/api/queries/branches/branches';
import { useGetMyTeams, useGetTeamMembers } from '@shared/api/queries/teams/teams';
import { useQueryClient } from '@tanstack/react-query';
import {
    BaseLayout,
    Icon,
    SkeletonTable,
    Pagination,
    getPagingParams,
    DataTable,
    ContentHeader
} from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useCurrentContributorContext } from 'app/CurrentContributorProvider';
import { getColumns } from './getColumns';
import { openDeleteModal } from './modals/DeleteModal';
import { openEditModal } from './modals/EditModal';
import { openInviteModal } from './modals/InviteModal';
import { TeamsFilter } from './TeamsFilter';

// eslint-disable-next-line no-duplicate-imports
import type { AppliedFilter, ItemsPerPageType } from '@uag/react-core';

export const Teams = () => {
    const { id } = useParams<{ id: string }>();
    const { currentContributor } = useCurrentContributorContext();
    const [searchText, setSearchText] = useState<string>('');
    const { t } = useTranslation();
    const [appliedSelectedPermission, setAppliedSelectedPermission] = useState<string | null>();
    const [appliedSelectedBranches, setAppliedSelectedBranches] = useState<string[]>();

    const queryClient = useQueryClient();

    const { data: myTeams, isLoading: isLoadingTeams } = useGetMyTeams();
    const [selectedTeamId, setSelectedTeamId] = useState<string>(id || '');
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [currentPage, setCurrentPage] = useState(1);
    const { data: filterableBranches } = useGetFilterableBranches();
    if (myTeams?.data && myTeams.data.length > 0 && !selectedTeamId) {
        setSelectedTeamId(myTeams.data[0].id || '');
    }

    const {
        data: teamMembers,
        isLoading: isLoadingMembers,
        queryKey
    } = useGetTeamMembers(selectedTeamId, { ...getPagingParams(currentPage, itemsPerPage) });

    const totalPages =
        teamMembers && teamMembers.metadata?.paging?.totalCount
            ? Math.ceil(teamMembers.metadata.paging.totalCount / itemsPerPage)
            : 1;

    const isLoading = isLoadingTeams || isLoadingMembers;

    const isTeamManager =
        teamMembers?.data?.length &&
        teamMembers.data.some(
            (member: TeamMemberModel) => member.memberType === 'Owner' && member.id === currentContributor.id
        );

    const handleInviteClick = () => {
        openInviteModal({
            title: t('inviteMember'),
            acceptButtonText: t('sendInvitation'),
            teamId: selectedTeamId
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const teamOptions = myTeams?.data?.map((team: TeamModel) => ({
        value: team.id,
        label: team.name
    }));

    const columns = getColumns();

    const handleEditClick = (member: TeamMemberModel) => {
        openEditModal({
            title: t('editTeamMember'),
            acceptButtonText: t('saveChanges'),
            memberName: member.displayName,
            teamId: selectedTeamId,
            memberType: member.memberType,
            contributorId: member.id
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const handleDeleteClick = (member: TeamMemberModel) => {
        openDeleteModal({
            title: `${t('removeMember')}?`,
            acceptButtonText: t('removeMember'),
            memberName: member.displayName,
            teamId: selectedTeamId,
            contributorId: member.id
        }).then(() => queryClient.invalidateQueries({ queryKey }));
    };

    const filteredMembers =
        teamMembers?.data?.filter(
            (member) =>
                member.displayName.toLowerCase().includes(searchText.toLowerCase()) &&
                (!appliedSelectedPermission || member.memberType === appliedSelectedPermission)
        ) || [];

    const appliedFilters: AppliedFilter[] = [];

    if (appliedSelectedPermission) {
        appliedFilters.push({ key: 'permission', value: appliedSelectedPermission, title: t('permission') });
    }

    if (appliedSelectedBranches) {
        appliedFilters.push(
            ...appliedSelectedBranches.map((branchId) => {
                const branch = filterableBranches?.data.find((b) => b.id === branchId);
                return {
                    key: `branch-${branchId}`,
                    value: branch?.name || 'Unknown',
                    title: 'Branch' as string
                };
            })
        );
    }

    return (
        <BaseLayout>
            <div className="flex flex-col gap-4 pb-base">
                <div className="flex justify-between gap-sm">
                    <ContentHeader title={t('responsibleTeam')} />

                    <div className="flex gap-4">
                        {!isLoadingTeams && teamOptions?.length && teamOptions.length > 1 && (
                            <Select
                                className="w-fit justify-start"
                                data={teamOptions}
                                value={selectedTeamId}
                                searchable
                                onChange={(value: string | null) => setSelectedTeamId(value ?? '')}
                            />
                        )}
                        {isTeamManager && (
                            <ContentHeader.Actions>
                                <Button onClick={handleInviteClick}>
                                    <span>{t('inviteMember')}</span>
                                    <Icon className="ml-base" icon="person_add" />
                                </Button>
                            </ContentHeader.Actions>
                        )}
                    </div>
                </div>

                <div className="flex w-full justify-between gap-1">
                    <div className="w-full">
                        <TeamsFilter
                            appliedSelectedBranches={appliedSelectedBranches}
                            appliedSelectedPermission={appliedSelectedPermission}
                            searchText={searchText}
                            setAppliedSelectedBranches={setAppliedSelectedBranches}
                            setAppliedSelectedPermission={setAppliedSelectedPermission}
                            onSearchTextChanged={setSearchText}
                        />
                    </div>
                </div>

                {isLoading && <SkeletonTable columns={3} rows={5} />}
                {!isLoading && (
                    <>
                        <DataTable columns={columns} data={filteredMembers} withHeader>
                            <DataTable.RowActions type="ActionIcons">
                                <DataTable.RowAction icon="edit" label="Edit" onClick={handleEditClick} />
                                <DataTable.RowAction icon="delete" label="Delete" onClick={handleDeleteClick} />
                                <DataTable.RowActionDivider />
                            </DataTable.RowActions>
                        </DataTable>
                        <Pagination
                            className="mt-8"
                            itemsPerPage={itemsPerPage}
                            page={currentPage}
                            totalPages={totalPages}
                            onItemsPerPageChange={setItemsPerPage}
                            onPageChange={setCurrentPage}
                        />
                    </>
                )}
            </div>
        </BaseLayout>
    );
};
