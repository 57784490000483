import { variants } from '@uag/react-core';

import { RequestStateType } from 'api/models';

export const getBadgeVariant = (state: RequestStateType): string => {
    switch (state) {
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.ServiceActivation:
        case RequestStateType.ContractSigning:
        case RequestStateType.AdditionalDataInput:
        case RequestStateType.Invited:
        case RequestStateType.DataInput:
            return variants.badge.default;
        case RequestStateType.DataVerification:
        case RequestStateType.SignatureVerification:
            return variants.badge.warning;
        case RequestStateType.Completed:
            return variants.badge.success;
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return variants.badge.alert;
        default:
            return '';
    }
};

/*
    "CustomerRequestState": {
        "CoreSystemActivation": "Pending",
        "DataVerification": "Pending",
        "ServiceActivation": "Pending",
        "SignatureVerification": "Pending"
        "AdditionalDataInput": "Action required",
        "ContractSigning": "Action required",
        "DataInput": "Action required",
        "Invited": "Action required",
        "Declined": "Declined",
        "Aborted": "Declined",
        "Completed": "Completed",
    },
*/
export const getCustomerBadgeVariant = (state: RequestStateType): string => {
    switch (state) {
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.DataVerification:
        case RequestStateType.ServiceActivation:
        case RequestStateType.SignatureVerification:
            return variants.badge.default;
        case RequestStateType.AdditionalDataInput:
        case RequestStateType.ContractSigning:
        case RequestStateType.DataInput:
        case RequestStateType.Invited:
            return variants.badge.warning;
        case RequestStateType.Completed:
            return variants.badge.success;
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return variants.badge.alert;
        default:
            return '';
    }
};
