import { Anchor, Notification } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import {
    getGetRequestDetailQueryKey,
    getGetRequestsQueryKey,
    useVerifyRequest
} from '@shared/api/queries/requests/requests';
import { useQueryClient } from '@tanstack/react-query';
import { getExceptionDetails, toastNotifications } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCurrentContributorContext } from 'app/CurrentContributorProvider';
import { useRequestContext } from '../RequestProvider';
import { checkForDokaResponsibleChange } from './functions';

export const AdditionalInputRequiredAction = () => {
    const { t } = useTranslation();
    const { mutateAsync: verifyRequest } = useVerifyRequest();
    const queryClient = useQueryClient();
    const { request } = useRequestContext();
    const { currentContributor } = useCurrentContributorContext();
    const [isLoading, setIsLoading] = useState(false);

    if (request.currentState !== 'DataVerification') {
        return null;
    }

    const handleClick = async () => {
        if (await checkForDokaResponsibleChange(request, currentContributor)) {
            return;
        }

        setIsLoading(true);
        const id = randomId();
        toastNotifications.loading({ id, title: 'Saving', message: 'Saving changes to the server.' });

        try {
            await verifyRequest({ id: request.id, data: { verifyState: 'AdditionalInputRequired' } });
            toastNotifications.success({
                title: t('success'),
                message: t('requestActionSuccess', { action: t('setToAdditionalDataInput') })
            });
        } catch (error) {
            toastNotifications.error({
                title: t('failed'),
                message: t('requestActionFailed', {
                    action: t('setToAdditionalDataInput'),
                    reason: getExceptionDetails(error)?.message
                })
            });
        } finally {
            toastNotifications.hide(id);
            setIsLoading(false);
        }
        queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(request.id) });
        queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
    };

    return (
        <Notification classNames={{ body: 'flex-wrap' }} title={t('dataSeemsInvalid')} withCloseButton={false}>
            <div>
                <Trans
                    components={{
                        requester: <Anchor className={clsx({ ['link-disabled']: isLoading })} onClick={handleClick} />
                    }}
                    i18nKey="requestMoreInfos"
                    values={{ additionalDataInputRequired: t('additionalDataInputRequired') }}
                />
            </div>
        </Notification>
    );
};
