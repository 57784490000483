/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */

export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttachmentType = {
  BusinessLicense: 'BusinessLicense',
  Contract: 'Contract',
  VATCertificate: 'VATCertificate',
  RegisteredNationalAddress: 'RegisteredNationalAddress',
} as const;
