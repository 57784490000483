import { Button } from '@mantine/core';
import {
    getGetRequestDetailQueryKey,
    getGetRequestsQueryKey,
    useVerifyRequest
} from '@shared/api/queries/requests/requests';
import { useQueryClient } from '@tanstack/react-query';
import { getExceptionDetails, Icon, toastNotifications, variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { useCurrentContributorContext } from 'app/CurrentContributorProvider';
import { openRemarkModal } from '../modals/RemarkModal';
import { useRequestContext } from '../RequestProvider';
import { checkForDokaResponsibleChange } from './functions';
import { RequestActionButtonProps } from './types';

export const DeclineRequestAction = (_: RequestActionButtonProps) => {
    const { t } = useTranslation();
    const { mutateAsync: verifyRequest, isPending: isVerifyPending } = useVerifyRequest();
    const queryClient = useQueryClient();
    const { request } = useRequestContext();
    const { currentContributor } = useCurrentContributorContext();

    const handleDecline = async () => {
        if (await checkForDokaResponsibleChange(request, currentContributor)) {
            return;
        }

        openRemarkModal({
            title: t('declineCustomerQuestion'),
            acceptButtonVariant: 'danger',
            acceptButtonText: t('declineCustomer')
        }).then(async (remarks) => {
            try {
                await verifyRequest({ id: request.id, data: { verifyState: 'Decline', annotations: [remarks] } });
                toastNotifications.success({
                    title: t('success'),
                    message: t('requestActionSuccess', { action: t('declined') })
                });
            } catch (error) {
                toastNotifications.error({
                    title: t('failed'),
                    message: t('requestActionFailed', {
                        action: t('declined'),
                        reason: getExceptionDetails(error)?.message
                    })
                });
            }
            queryClient.invalidateQueries({ queryKey: getGetRequestDetailQueryKey(request.id) });
            queryClient.invalidateQueries({ queryKey: getGetRequestsQueryKey() });
        });
    };

    return (
        <Button loading={isVerifyPending} variant={variants.button.secondary} onClick={handleDecline}>
            {t('decline')}
            <Icon className="ml-base" icon="block" />
        </Button>
    );
};
