import { Notifications } from '@mantine/notifications';
import { getCustomThemeOverrides } from '@shared/theming/getCustomThemeOverrides';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    AuthProvider,
    MantineOverrideProvider,
    Theme,
    ThemeProvider,
    SidebarNavigationProvider,
    DefaultErrorBoundary
} from '@uag/react-core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router';

import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { router } from './AppRoutes';

// eslint-disable-next-line no-duplicate-imports
import type { SidebarNavigationLinkConfig } from '@uag/react-core';

import './App.css';
import '../i18n';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            staleTime: 0
        }
    }
});

export const App = () => {
    const { t } = useTranslation();

    const navLinks: SidebarNavigationLinkConfig[] = [
        {
            label: t('requests'),
            link: '/requests',
            icon: 'inbox'
        },
        {
            label: t('responsibleTeam'),
            link: '/teams',
            icon: 'group'
        }
    ];
    const customThemeOverrides = getCustomThemeOverrides();

    useEffect(() => {
        document.title = t('pageTitle');
    }, [t]);

    return (
        <ThemeProvider mail="registration@doka.com" theme={Theme.Doka}>
            <MantineOverrideProvider customThemeOverrides={customThemeOverrides}>
                <AuthProvider configuration={configurationIdentityServer}>
                    <QueryClientProvider client={queryClient}>
                        <AppInsightsContextProvider>
                            <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                <Notifications />
                                <SidebarNavigationProvider navLinks={navLinks}>
                                    <RouterProvider router={router} />
                                </SidebarNavigationProvider>
                            </DefaultErrorBoundary>
                        </AppInsightsContextProvider>
                    </QueryClientProvider>
                </AuthProvider>
            </MantineOverrideProvider>
        </ThemeProvider>
    );
};
