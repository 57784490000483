import { Loader, TextInput } from '@mantine/core';
import { getExceptionDetails, Icon, SelectableCard, variants } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { welcomeApiClient } from 'api/customMutator';
import { GetCompanyExistsResponse, GetOnboardingRequestsResponse } from 'api/models';
import { getCompanyExistsQueryKey } from 'api/queries/companies/companies';
import { getGetRequestsQueryKey } from 'api/queries/requests/requests';
import { Card } from '../Card';
import existingCustomer from './assets/existingCustomer.svg';
import newCustomer from './assets/newCustomer.svg';
export const CustomerType = {
    New: 'New',
    Existing: 'Existing'
};

export type CustomerType = (typeof CustomerType)[keyof typeof CustomerType];

type Props = {
    onCustomerTypeChanged: (value: CustomerType) => void;
    customerType: CustomerType;
    onCustomerNumberChanged: (value: string) => void;
    customerNumberError?: string;
};
export const CustomerSelection = ({
    customerType,
    onCustomerTypeChanged: handleCustomerTypeChanged,
    onCustomerNumberChanged: handleCustomerNumberChange
}: Props) => {
    const { t } = useTranslation();
    const [isCustomerNumberValidationPending, setIsCustomerNumberValidationPending] = useState(false);
    const [customerNumberError, setCustomerNumberError] = useState<string | undefined>();
    const [customerNumber, setCustomerNumber] = useState<string>('');
    const [isCustomerNumberValid, setIsCustomerNumberValid] = useState<boolean | undefined>();
    const navigate = useNavigate();

    const handleCustomerNumberBlur = async (value: string) => {
        handleCustomerNumberChange('');
        setCustomerNumberError(undefined);
        setIsCustomerNumberValidationPending(true);
        setIsCustomerNumberValid(undefined);

        if (!value) {
            return;
        }

        try {
            const existingRequests = await welcomeApiClient.get<GetOnboardingRequestsResponse>(
                getGetRequestsQueryKey().join()
            );

            const existing = existingRequests.data.data.filter(
                (response) => response.companyData.customerIdentifier === value
            );

            if (existing && existing.length >= 1) {
                navigate(`/request/${existing[0].id}`);
                return;
            }

            const response = await welcomeApiClient.get<GetCompanyExistsResponse>(
                getCompanyExistsQueryKey(value).join()
            );
            if (response.data.data?.exists) {
                setIsCustomerNumberValid(true);
                handleCustomerNumberChange(value);
            } else {
                setCustomerNumberError(t('customerNotFound'));
                setIsCustomerNumberValid(false);
            }
        } catch (error) {
            setIsCustomerNumberValid(true);
            setCustomerNumberError(getExceptionDetails(error)?.message);
        } finally {
            setIsCustomerNumberValidationPending(false);
        }
    };

    const rightSection = isCustomerNumberValidationPending ? (
        <Loader />
    ) : isCustomerNumberValid === true ? (
        <Icon color="green" icon="check" />
    ) : isCustomerNumberValid === false ? (
        <Icon color="red" icon="close" />
    ) : (
        <Icon icon="circle" />
    );

    return (
        <div className="flex flex-col gap-1">
            <h4 className="font-base font-default leading-base text-base font-normal">
                {t('customerType')}
                <span className="text-text-alert">*</span>
            </h4>
            <div className="flex flex-col gap-4">
                <SelectableCard.Group
                    className="w-full gap-6"
                    orientation="horizontal"
                    value={customerType}
                    wrap="nowrap"
                    onChange={handleCustomerTypeChanged}
                >
                    <Card
                        image={existingCustomer}
                        imageClassName="h-40"
                        text={t('existingCustomer')}
                        value={CustomerType.Existing}
                    />
                    <Card
                        className={clsx({ ['card-selected']: customerType === CustomerType.New })}
                        image={newCustomer}
                        imageClassName="h-40"
                        text={t('newCustomer')}
                        value={CustomerType.New}
                    />
                </SelectableCard.Group>
                {customerType === CustomerType.Existing && (
                    <TextInput
                        classNames={{ section: 'border-l-0' }}
                        description={t('customerNumberDescription')}
                        error={customerNumberError}
                        label={t('customerNumber')}
                        placeholder={t('customerNumberPlaceholder')}
                        rightSection={rightSection}
                        value={customerNumber}
                        variant={variants.textField.textDecoration}
                        required
                        onBlur={(event) => handleCustomerNumberBlur(event.target.value)}
                        onChange={(event) => setCustomerNumber(event.target.value)}
                    />
                )}
            </div>
        </div>
    );
};
