import { Button, Checkbox, SimpleGrid } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { OnboardingRequestModel, OnboardingRequestModelCurrentState, RequestStateType } from '@shared/api/models';
import { useGetRequests } from '@shared/api/queries/requests/requests';
import {
    BaseLayout,
    ErrorView,
    getExceptionDetails,
    Inbox,
    Filter,
    ContentHeader,
    InboxGroup,
    InboxItem,
    LoadingAnimation,
    Icon,
    variants
} from '@uag/react-core';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { managementInstance } from 'i18n';
import { BranchCombobox } from 'shared';
import { ResponsiblePersonCombobox } from 'shared/components/ResponsiblePersonCombobox';
import { openInviteCompanyModal } from './InviteCompany/InviteCompanyModal';
import {
    stateFilterAtom,
    appliedStateFilterAtom,
    branchFilterAtom,
    appliedBranchFilterAtom,
    responsiblePersonFilterAtom,
    appliedResponsiblePersonFilterAtom,
    searchTextAtom
} from './RequestAtoms';

// eslint-disable-next-line no-duplicate-imports
import type { AppliedFilter, InboxItemType } from '@uag/react-core';

export const RequestsBaseRoute = '/requests';

const getStatus = (state: OnboardingRequestModelCurrentState | undefined) => {
    switch (state) {
        case RequestStateType.Invited:
        case RequestStateType.DataInput:
        case RequestStateType.AdditionalDataInput:
        case RequestStateType.ContractSigning:
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.ServiceActivation:
            return undefined;
        case RequestStateType.SignatureVerification:
        case RequestStateType.DataVerification:
            return 'warning';
        case RequestStateType.Completed:
            return 'success';
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return 'alert';
        default:
            throw new Error(`Unknown state: ${state}`);
    }
};

type OnboardingInboxItem = InboxItemType & {
    id: string;
};

const getInboxItem = (data: OnboardingRequestModel): OnboardingInboxItem => ({
    id: data.id,
    name: data.dokaOnboardingResponsible?.model.displayName,
    img: data.dokaOnboardingResponsible?.model.profilePictureUri ?? undefined,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    company: data.companyData.companyName!,
    place: data.branch?.name ?? undefined,
    time: new Date(data.createdDate),
    status: {
        status: getStatus(data.currentState),
        message: (data.currentState && managementInstance.t(`requestStateType.${data.currentState}`)) ?? ''
    }
});

const getStateFilterValues = () => {
    return Object.values(RequestStateType).map((state) => ({
        value: state,
        label: managementInstance.t(`requestStateType.${state}`)
    }));
};

export const Requests = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();
    const [searchText, setSearchText] = useAtom(searchTextAtom);
    const [debounceSearchText] = useDebouncedValue(searchText, 500);

    const [stateFilter, setStateFilter] = useAtom(stateFilterAtom);
    const [appliedStateFilter, setAppliedStateFilter] = useAtom(appliedStateFilterAtom);

    const [branchFilter, setBranchFilter] = useAtom(branchFilterAtom);
    const [appliedBranchFilter, setAppliedBranchFilter] = useAtom(appliedBranchFilterAtom);

    const [responsiblePersonFilter, setResponsiblePersonFilter] = useAtom(responsiblePersonFilterAtom);
    const [appliedResponsiblePersonFilter, setAppliedResponsiblePersonFilter] = useAtom(
        appliedResponsiblePersonFilterAtom
    );

    const { data, isLoading, error } = useGetRequests(
        {
            state: appliedStateFilter ?? undefined,
            customerSearchText: debounceSearchText,
            branchIds: appliedBranchFilter ? [appliedBranchFilter] : undefined,
            contributorIds: appliedResponsiblePersonFilter ? [appliedResponsiblePersonFilter] : undefined
        },
        { query: { refetchOnWindowFocus: true } }
    );

    const filteredItems =
        data?.data.filter((item) => !!appliedStateFilter || item.currentState !== RequestStateType.DataInput) ?? [];

    const completedItems = filteredItems.filter(
        (item) => item.currentState === RequestStateType.Completed || item.currentState === RequestStateType.Declined
    );

    const ongoingItems = filteredItems.filter((item) => !completedItems.includes(item));

    const itemGroups = [
        { title: t('ongoing'), items: ongoingItems },
        { title: t('completed'), items: completedItems }
    ];

    const handleStateFilterChange = (stateValue: RequestStateType, checked: boolean) => {
        setStateFilter((prev) => {
            if (checked) {
                return prev ? [...prev, stateValue] : [stateValue];
            } else {
                return prev?.filter((v) => v !== stateValue) || null;
            }
        });
    };

    const handleFilterModalClosed = (applyFilters: boolean) => {
        if (applyFilters) {
            setAppliedStateFilter(stateFilter);
            setAppliedBranchFilter(branchFilter);
            setAppliedResponsiblePersonFilter(responsiblePersonFilter);
        } else {
            setStateFilter(appliedStateFilter);
            setBranchFilter(appliedBranchFilter);
            setResponsiblePersonFilter(appliedResponsiblePersonFilter);
        }
    };

    const handleClearFilters = () => {
        setStateFilter(null);
        setAppliedStateFilter(null);
        setBranchFilter(null);
        setResponsiblePersonFilter(null);
    };

    const openItem = (item: OnboardingInboxItem) => {
        navigate(`${RequestsBaseRoute}/${item.id}`);
    };

    useEffect(() => {
        if (data?.data && data?.data.length > 0 && location.pathname.endsWith(RequestsBaseRoute)) {
            navigate(`${RequestsBaseRoute}/${data?.data[0].id}`);
        }
    }, [data?.data, location.pathname, navigate]);

    const appliedFilters: { key: string; value: string; title: string }[] = [];

    if (appliedBranchFilter) {
        appliedFilters.push({ key: 'branchFilter', value: appliedBranchFilter, title: t('branch') });
    }

    if (appliedResponsiblePersonFilter) {
        appliedFilters.push({
            key: 'responsiblePersonFilter',
            value: appliedResponsiblePersonFilter,
            title: t('responsiblePerson')
        });
    }

    if (appliedStateFilter && Array.isArray(appliedStateFilter)) {
        appliedFilters.push(
            ...appliedStateFilter.map((state) => ({
                key: `${state}`,
                value: state as string,
                title: ''
            }))
        );
    }

    const handleClearAppliedFilter = (filter: AppliedFilter) => {
        switch (filter.key) {
            case 'branchFilter':
                setAppliedBranchFilter(null);
                break;
            case 'responsiblePersonFilter':
                setAppliedResponsiblePersonFilter(null);
                break;
            default:
                if (appliedStateFilter && Array.isArray(appliedStateFilter)) {
                    const newAppliedFilters = appliedStateFilter.filter((state) => state !== filter.value);

                    setAppliedStateFilter(newAppliedFilters);
                    setStateFilter(newAppliedFilters);
                }
                break;
        }
    };

    const handleInviteCompany = () => {
        openInviteCompanyModal();
    };

    return (
        <BaseLayout versionNumber={APP_VERSION}>
            {error && <ErrorView description={getExceptionDetails(error)?.message} />}
            {!error && (
                <>
                    <Inbox>
                        <ContentHeader title={t('requests')}>
                            <ContentHeader.Actions>
                                <Button variant={variants.button.secondary} onClick={handleInviteCompany}>
                                    {t('inviteCompany')}
                                    <Icon className="ml-2" icon="add" />
                                </Button>
                            </ContentHeader.Actions>
                        </ContentHeader>

                        <Filter
                            appliedFilters={appliedFilters}
                            filterButtonType="icon-only"
                            searchText={searchText}
                            onClearAppliedFilter={handleClearAppliedFilter}
                            onClearFilters={handleClearFilters}
                            onFilterDrawerClose={handleFilterModalClosed}
                            onSearchTextChanged={setSearchText}
                        >
                            <Filter.Filters>
                                <BranchCombobox
                                    key="branchFilter"
                                    value={branchFilter}
                                    onChange={(value) => setBranchFilter(value ?? null)}
                                />
                                <ResponsiblePersonCombobox
                                    key="responsiblePersonFilter"
                                    value={responsiblePersonFilter}
                                    onChange={(value) => setResponsiblePersonFilter(value ?? null)}
                                />
                                <SimpleGrid>
                                    <p className="text-base py-1">{t('requestState')}</p>
                                    {getStateFilterValues().map((state) => (
                                        <Checkbox
                                            key={state.value}
                                            checked={stateFilter?.includes(state.value) || false}
                                            label={state.label}
                                            onChange={(e) => handleStateFilterChange(state.value, e.target.checked)}
                                        />
                                    ))}
                                </SimpleGrid>
                            </Filter.Filters>
                        </Filter>
                        {isLoading && <LoadingAnimation />}
                        <div className="inbox-entries">
                            {!isLoading &&
                                itemGroups.map((group, index) => (
                                    <InboxGroup key={index} title={group.title as string}>
                                        {group.items.map((item) => (
                                            <InboxItem
                                                key={item.id}
                                                data={getInboxItem(item)}
                                                isSelected={location.pathname.endsWith(item.id)}
                                                onClick={openItem}
                                            />
                                        ))}
                                    </InboxGroup>
                                ))}
                        </div>
                    </Inbox>
                    <div className="w-full p-base">{!isLoading && <Outlet />}</div>
                </>
            )}
        </BaseLayout>
    );
};
