import { useGetFilterableBranches } from '@shared/api/queries/branches/branches';
import { Select, Filter, AppliedFilter } from '@uag/react-core';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TeamsFilterProps = {
    searchText: string;
    appliedSelectedPermission?: string | null;
    appliedSelectedBranches?: string[];
    onSearchTextChanged: (text: string) => void;
    setAppliedSelectedPermission: (permission: string | null | undefined) => void;
    setAppliedSelectedBranches: (branches: string[]) => void;
};

export const TeamsFilter = ({
    searchText,
    appliedSelectedPermission,
    appliedSelectedBranches = [],
    onSearchTextChanged,
    setAppliedSelectedPermission,
    setAppliedSelectedBranches
}: TeamsFilterProps) => {
    const { t } = useTranslation();
    const { data: filterAbleBranches } = useGetFilterableBranches();
    const [selectedPermission, setSelectedPermission] = useState<string | null | undefined>(null);
    const [selectedBranches, setSelectedBranches] = useState<string[]>([]);

    const permissionsData = [
        { value: 'Owner', label: t('editor') },
        { value: 'Member', label: t('teamMember') }
    ];

    const appliedFilters = useMemo(() => {
        const filters: AppliedFilter[] = [];
        if (appliedSelectedPermission) {
            filters.push({ key: 'permission', value: appliedSelectedPermission, title: t('permission') });
        }
        if (appliedSelectedBranches?.length) {
            filters.push(
                ...appliedSelectedBranches.map((branchId) => {
                    const branch = filterAbleBranches?.data.find((b) => b.id === branchId);
                    return { key: `branch-${branchId}`, value: branch?.name || 'Unknown', title: 'Branch' };
                })
            );
        }
        return filters;
    }, [appliedSelectedPermission, appliedSelectedBranches, filterAbleBranches, t]);

    const handleFilterDrawerClosed = (applyFilters: boolean) => {
        if (applyFilters) {
            setAppliedSelectedPermission(selectedPermission);
            setAppliedSelectedBranches([...selectedBranches]);
        } else {
            setSelectedPermission(appliedSelectedPermission);
            setSelectedBranches([...(appliedSelectedBranches ?? [])]);
        }
    };

    const handleClearAppliedFilter = (filter: AppliedFilter) => {
        if (filter.key === 'permission') {
            setAppliedSelectedPermission(null);
            setSelectedPermission(null);
        }
        if (filter.key.startsWith('branch-')) {
            const branchToRemove = filterAbleBranches?.data.find((b) => b.name === filter.value);
            if (branchToRemove) {
                setAppliedSelectedBranches([
                    ...(appliedSelectedBranches?.filter((id) => id !== branchToRemove?.id) ?? [])
                ]);
            }
        }
    };

    const handleClearFilter = () => {
        setAppliedSelectedBranches([]);
        setAppliedSelectedPermission(null);
        setSelectedPermission(null);
    };

    return (
        <Filter
            appliedFilters={appliedFilters}
            searchText={searchText}
            onClearAppliedFilter={handleClearAppliedFilter}
            onClearFilters={handleClearFilter}
            onFilterDrawerClose={handleFilterDrawerClosed}
            onSearchTextChanged={onSearchTextChanged}
        >
            <Filter.Filters>
                <Select
                    className="w-full"
                    data={permissionsData}
                    label={t('permission')}
                    value={selectedPermission}
                    allowDeselect
                    clearable
                    searchable
                    onChange={(value) => setSelectedPermission(value)}
                />
            </Filter.Filters>
        </Filter>
    );
};
